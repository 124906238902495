@import url("https://fonts.googleapis.com/css2?family=Akaya+Telivigala&family=Itim&display=swap");

.App {
  overflow: hidden;
}

img {
  width: 100%;
}

a {
  cursor: pointer;
  color: inherit;
}

.bg-header {
  background: linear-gradient(89deg, #ff5c00 38.12%, #0dd 115.38%);
}

body {
  font-family: "Itim", cursive;
}

.link {
  background: linear-gradient(83deg, #1bef52 -53.94%, #0dd 62.91%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-box {
  border-radius: 113px 113px 0px 0px;

  background: linear-gradient(87deg, #ff5c00 45.57%, #0dd 140.76%);
}

.flip-animation {
  animation: flip 2s linear infinite alternate;
}

@keyframes flip {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(180deg);
  }
}
